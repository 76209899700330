<template>
  <div class="customReports container">

	<p class="h6 text-center text-primary"><b>{{ reportName }} - Quantity</b></p>    
	<br>

	<CCard :color="'secondary'">
		<CCardBody>
			<form class="form-inline">
				<div class="form-group">
					<label class="font-weight-bold mr-1" for="fromdate">From:</label>
					<datepicker :disabled-dates="disabledDates" class="pr-2" 
							v-model="fromdate" name="fromdate"></datepicker>

					<label class="font-weight-bold mr-1" for="todate">To:</label>
					<datepicker :disabled-dates="disabledDates" class="pr-4" v-model="todate" name="todate"></datepicker>

					<select v-model="timescale" class="form-control form-control-sm mr-4">
						<option selected value="Aggregate">Aggregate</option>
						<option value="Daily">Daily</option>
						<option value="Weekly">Weekly</option>
						<option value="Monthly">Monthly</option>
					</select>

					<CButton color="dark" v-on:click.prevent="serverCall('getCustomReportData')">Fetch Data</CButton>
				</div>

				<div>
					<label for="loadingMsg" v-if="loading">{{loadingMsg}}</label>
					<scale-loader :loading="loading"></scale-loader>
				</div>
			</form>	
		</CCardBody>
	</CCard>

	<p class="text-danger" align="left">{{alert}}</p>

	<div>
		<div class="btn-group">
			<CButton color="secondary" class="mr-2" v-on:click.prevent="$refs.reportTable.expandAll()">Expand All</CButton>
			<CButton color="secondary" v-on:click.prevent="$refs.reportTable.collapseAll()">Collapse All</CButton>
		</div>
		
		<br>
		<div v-if="totalAmt!=0" class="text-right"><b>Total Amount: {{totalAmt}}</b>&nbsp;&nbsp;</div>
		<br>


		<vue-good-table
			ref="reportTable"
			:columns="columns"
			:rows="rows"
			:group-options="{
				enabled: true,
   				collapsable: true
			}"
			:row-style-class="rowStyleClassFn"
			:sort-options="{
				enabled: false,
			}"
			styleClass="vgt-table condensed"
		>
			<div slot="table-actions">
				<CButton color="dark" class="mr-2" v-on:click.prevent="downloadReport()">Download Report</CButton>
			</div>
		</vue-good-table>
	</div>

	<main id="main">
	</main>

  </div>
</template>

<script>
import axios from "axios"
import Datepicker from 'vuejs-datepicker';
import ScaleLoader from "@/views/utils/ScaleLoader";
import { firebaseAuth } from '../../firebaseConfig.js';
var FileSaver = require('file-saver')
import Helper from "../utils/Helper.vue"

export default {
	name: 'inventoryEntry',

	mixins: [
		Helper
	],

    components: {
		Datepicker,
        ScaleLoader,
	},
		
	data () {

		return {
			orgId: null,
			columns: [],
			rows: [],
			dataList: [],
			totalAmt: 0,

			reportName: this.$route.params.reportName,
			orderType: this.$route.params.orderType,
			reportLongName: "",
			reportData: "",
			fromdate: null,
			todate: null,
			timescale: "Aggregate",

			disabledDates: {
				//to: new Date(2016, 0, 5), // Disable all dates up to specific date
				from: new Date(), // Disable all dates after specific date
			},

			activeTab: 'In',

			alert: null,
			loading: false,
		
		};
	},

	computed: {
	},

	mounted(){
		// var dt = new Date()
		// dt = dt.toISOString()
		// console.log(dt)
		// return
		this.orgId = this.$route.params['orgId']
		this.fromdate = new Date()
		this.todate = new Date()
		this.reportName = this.$route.params['reportName']
		this.orderType = this.$route.params['orderType']
		if (!this.orderType){
			this.orderType = 'Inventory'
		}
	},

	methods: {
		rowStyleClassFn(row) {
			if (row.color == 1){
				return 'active'
			}
		},

        serverCall(name) {
            var self = this
			self.alert = null

			firebaseAuth.onAuthStateChanged(user => {
				if (user) {
					user.getIdToken(true).then(idToken => {
						switch(name) {
							case "getCustomReportData":
								self.getCustomReportData(idToken)
								break;

							default:
								console.log("Unknown function: " + name)
						}
					})
				}
				else {
					self.alert = "Please login first."
				}
			})
		},
		

        // Get Report Data for the given dates.
        getCustomReportData(authToken) {
            var self = this
			self.alert = null

            if (!this.fromdate || !this.todate || !this.reportName){
                self.alert = "Either fromdate or todate or reportName not specified."
                return
			}

			// Set loading message
			self.loading = true
			self.loadingMsg = "Fetching Data..."

			var postJson = {
				'reportName': this.reportName,
				'fromDate': this.getMidDay(this.fromdate),
				'toDate': this.getMidDay(this.todate),
				'timescale': this.timescale,
				'orderType': this.orderType
			}

			var options = { year: '2-digit', month: 'short', day: 'numeric' }
			var fdStr = this.fromdate.toLocaleDateString("en-US", options)
			var tdStr = this.todate.toLocaleDateString("en-US", options)

			axios({
					method: 'POST',
					baseURL: process.env.VUE_APP_ROOT_API,
					url: '/GetCustomReportData/',
					data: postJson,
					headers: {
						'Authorization': 'Bearer ' + authToken,
						'OrgId': self.orgId,
						'Content-Type': 'application/json',
					},
				})
				.then(function (response) {
					if (response.data.err != ''){
						self.alert = response.data.err
						console.log("getLocationReportData - error:" + response.data.err)
					}
					else {
						self.columns = response.data.columns
						self.rows = response.data.dataList
						self.totalAmt = response.data.totalAmt
						self.reportLongName = self.reportName + " - " + fdStr + " To " + tdStr
						self.reportData = response.data.reportData
					}
					// Reset loading message
					self.loading = false
					self.loadingMsg = null
				})
				.catch(function (error) {
					self.alert = String(error)
					// Reset loading message
					self.loading = false
					self.loadingMsg = null
				})
		},


		downloadReport() {
			if (this.reportData==[]){
				alert("No report data.")
				return
			}
			let blob = new Blob([this.base64toBlob(this.reportData, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')], {});
			FileSaver.saveAs(blob, this.reportLongName + '.xlsx');
		},

	},

}
</script>

<style>
	.blue {
		background-color: #F0F8FF;
	}

	.yellow {
		background-color: #90EE90;
	}

	.active {
		color : green;
	}
</style>

